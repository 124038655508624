import React from 'react';
import { BsArrowsCollapseVertical, BsHouse, BsBarChartLine, BsFileEarmarkText, BsHourglassSplit, BsClipboard2Minus, BsClipboard2Plus, BsDatabaseAdd, BsTag, BsBoxArrowRight } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

const LeftBar = ({ leftBarCollapsed, setLeftBarCollapsed, handleLogout, setSelectedTab }) => {
  const location = useLocation();

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName); // Update the selectedTab state in App.js
    localStorage.setItem('selectedTab', tabName); // Persist the selected tab in localStorage
  };

  return (
    <div className={`left-bar ${leftBarCollapsed ? 'collapsed' : ''}`}>
      <div className="collapse-btn" onClick={() => setLeftBarCollapsed(!leftBarCollapsed)}>
      <BsArrowsCollapseVertical className='icon-collapse' />
      </div>
      <ul>
        <Link to="/Dashboard" onClick={() => handleTabClick('dashboard')}>
          <li><span className="icon">
            <BsHouse /></span> {!leftBarCollapsed && 'Dashboard'}
          </li>
        </Link>
        <Link to="/TableStockCount" onClick={() => handleTabClick('stockCount')}>
          <li><span className="icon">
            <BsBarChartLine /> </span>{!leftBarCollapsed && 'Stock Count'}
          </li>
        </Link>
        <Link to="/TableInvoices" onClick={() => handleTabClick('invoices')}>
          <li><span className="icon">
            <BsFileEarmarkText /> </span>{!leftBarCollapsed && 'All Invoices'}
          </li>
        </Link>
        <Link to="/PendingInvoices" onClick={() => handleTabClick('pending')}>
          <li><span className="icon">
            <BsHourglassSplit /> </span>{!leftBarCollapsed && 'Pending Goods'}
          </li>
        </Link>
        <Link to="/TableStockLost" onClick={() => handleTabClick('stockLost')}>
          <li><span className="icon">
            <BsClipboard2Minus /></span> {!leftBarCollapsed && 'Stock Lost'}
          </li>
        </Link>
        <Link to="/TableStockReplenished" onClick={() => handleTabClick('stockReplenished')}>
          <li><span className="icon">
            <BsClipboard2Plus /></span> {!leftBarCollapsed && 'Stock Replenished'}
          </li>
        </Link>
        <Link to="/addProducts" onClick={() => handleTabClick('addProducts')}>
          <li><span className="icon">
            <BsDatabaseAdd /> </span>{!leftBarCollapsed && 'Add Products'}
          </li>
        </Link>
        <Link to="/tableSku" onClick={() => handleTabClick('tableSku')}>
          <li>
          <span className="icon">
            <BsTag />  </span>{!leftBarCollapsed && 'Product SKUs'}
           
          </li>
        </Link>
        <Link to="#" onClick={handleLogout}>
          <li><span className="icon">
            <BsBoxArrowRight /> </span>{!leftBarCollapsed && 'Logout'}
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default LeftBar;

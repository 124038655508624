import React, { useState, useEffect } from 'react';
import { HashRouter as Router , Route, Routes, useLocation } from 'react-router-dom';
import TableInvoices from './pages/TableInvoices';
import PendingInvoices from './pages/PendingInvoices';
import TableSales from './pages/TableSales';
import TableStockLost from './pages/TableStockLost';
import TableStockCount from './pages/TableStockCount';
import TableStockReplenished from './pages/TableStockReplenished';
import AddProducts from './pages/addProducts';
import TableSku from './pages/TableSku';
import Dashboard from './pages/Dashboard';
import Layout from './components/Layout'; // Import the Layout component

// import '../app/globals.css';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Your initialization code that relies on the browser environment
    }
  }, [location]); // Added location dependency to re-run effect on route changes

  return null; // Home component doesn't need to return anything since it's used for the useEffect
};

const App = () => {
  const [selectedTab, setSelectedTab] = useState('stockCount');
  const [tabCounter, setTabCounter] = useState(0); // Counter to force re-render

  const handleTabChange = (newTab) => {
    setSelectedTab(newTab);
    setTabCounter((prev) => prev + 1); // Increment counter to force re-render
  };

  return (
    <Router>
      <Layout setSelectedTab={handleTabChange}> {/* Pass handleTabChange as a prop */}
        <Routes>
          <Route 
            key={`${selectedTab}-${tabCounter}`} // Ensure route re-renders on tab change
            path="/" 
            element={<TableStockCount />} 
          />
          <Route 
            key={`${selectedTab}-${tabCounter}`}
            path="/Dashboard" 
            element={<Dashboard />} 
          />
          <Route 
            key={`${selectedTab}-${tabCounter}`} 
            path="/TableStockCount" 
            element={<TableStockCount />} 
          />
          <Route 
            key={`${selectedTab}-${tabCounter}`}
            path="/TableInvoices" 
            element={<TableInvoices />} 
          />
          <Route 
            key={`${selectedTab}-${tabCounter}`}
            path="/PendingInvoices" 
            element={<PendingInvoices />} 
          />
          <Route 
            key={`${selectedTab}-${tabCounter}`}
            path="/TableStockLost" 
            element={<TableStockLost />} 
          />
          <Route 
            key={`${selectedTab}-${tabCounter}`}
            path="/TableStockReplenished" 
            element={<TableStockReplenished />} 
          />
          <Route 
            key={`${selectedTab}-${tabCounter}`}
            path="/addProducts" 
            element={<AddProducts />} 
          />
          <Route 
            key={`${selectedTab}-${tabCounter}`}
            path="/tableSku" 
            element={<TableSku />} 
          />
        </Routes>
      </Layout>
    </Router>
  );
};


export default App;